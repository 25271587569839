import { faComment, faUser, faCheckCircle, faGift } from '@fortawesome/free-solid-svg-icons';
import CallBadgeTable from '../../common/CallBadgeTable';
import { BBadge } from 'bootstrap-vue';

export default {
  name: 'CallMobileTaskCard',
  components: {
    CallBadgeTable,
    BBadge
  },
  props: {
    objCard: {
      type: Object,
      default: () => ({})
    },
    commentNot: {
      type: Boolean,
      default: false
    },
    unvisibleTypeClosed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataIconComment: faComment,
      dataIconUser: faUser,
      dataIconCheckCircle: faCheckCircle,
      dataGiftIcon: faGift
    };
  },
  render (h) {
    return (
      <div class="task__item" onClick={() => this.$emit('on-action-click', this.objCard)}>
        <div class="task__title">
          <div class="task__title-wrap">

            {/* eslint-disable-next-line camelcase */}
            Player ID: {this.objCard?.player_id || 0}
            {this.objCard?.vip && <b-badge variant="vip">VIP</b-badge>}

            {this.objCard?.available_bonus_after_call && !this.objCard?.available_bonus &&
            <b-badge variant="bonus"><font-awesome-icon
                        icon={this.dataGiftIcon} /> Bonus Avaliable</b-badge>
                        }
            {this.objCard?.available_bonus_after_call === false &&
            this.objCard?.available_bonus === false &&
            <b-badge variant="no-bonus"><font-awesome-icon
                        icon={this.dataGiftIcon} /> No Bonuses</b-badge>
                        }

            {
              this.commentNot ? null
                // eslint-disable-next-line camelcase
                : <span class={this.objCard?.comment_count > 0 ? 'task__comment-icon is-active' : 'task__comment-icon'}>
                  {/* eslint-disable-next-line camelcase */}
                  &nbsp;{this.objCard?.comment_count || 0}&nbsp;
                  <font-awesome-icon
                    icon={this.dataIconComment}/>
                </span>
            }
          </div>
          {(this.objCard?.closed_by && !this.unvisibleTypeClosed) &&
            [
              <span class="card-project__closed-label" style="font-size: 14px;">
                {this.objCard?.closing_type} Closed
                <font-awesome-icon class="success ml-1" icon={this.dataIconCheckCircle}/>
              </span>
            ]
          }
        </div>
        {
          // eslint-disable-next-line camelcase
          this.objCard?.execute_before ? <call-badge-table type="warning">
            {/* eslint-disable-next-line camelcase */}
            {this.$options.filters.dateFilter(this.objCard?.execute_before) || ''}
          </call-badge-table> : null
        }

        {
          // eslint-disable-next-line camelcase
          this.objCard?.operator_name
            ? <call-badge-table type="success" icon={this.dataIconUser}>
              {/* eslint-disable-next-line camelcase */}
              {this.objCard?.operator_name || ''}
            </call-badge-table> : null
        }
      </div>
    );
  }
};
