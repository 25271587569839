<template>
  <div>
    <call-mobile-checked-group
      v-model="computedStoreModelFilter"
      :data-loaded="dataLoaded" />
    <div class="task__wrapper">
      <div class="task__group">
        <div
          v-if="computedStoreModelFilter === 'free'"
          :style="{
            'pointer-events': dataLoaded ? 'none' : 'painted',
          }"
          class="task__switch">
          <b-form-checkbox
            id="switch-id"
            v-model="newPlayer"
            name="check-button"
            switch
            @change="onNewPlayer" />
          <label
            for="switch-id"
            class="task__switch-title">Show Only New Players</label>
        </div>
        <div class="task__group-title">
          <div
            v-if="showTaskSort"
            class="task__sort"
            :class="dataDesc && 'is-desc'"
            :style="{
              'pointer-events': dataLoaded ? 'none' : 'painted',
            }"
            @click="clickSort">
            Sort By Date
            <div class="task__sort-icon" />
          </div>
        </div>
        <list
          v-model="dataLoadingList"
          :finished="dataLoadingListFinished"
          finished-text=""
          @load="onLoadList">
          <call-mobile-task-card
            v-for="item in computedSoreMobileTaskList"
            :key="item.id + item.player_id"
            :obj-card="item"
            unvisible-type-closed
            @on-action-click="onClickCard" />
        </list>
      </div>
    </div>
    <call-mobile-filter-phone
      v-model="dataShowFilter"
      @update="onFilterPhone" />
  </div>
</template>

<script>
import { TASKS_GET_ALL_MOBILE, PROJECTS_GET } from '../../../store/types/actions.types';
import { mapGetters } from 'vuex';
import { BFormCheckbox } from 'bootstrap-vue';
import { debounce } from 'lodash/function';
import CallMobileCheckedGroup from '../../../components/mobile/CallMobileCheckedGroup';
import CallMobileTaskCard from '../../../components/mobile/cards/CallMobileTasksCard';
import { List } from 'vant';
import eventBus from '../../../service/eventBus';
import helper from '../../../service/helper';
import { SET_ALL } from '@/store/types/mutations.types';
import CallMobileFilterPhone from '@/components/mobile/modal/CallMobileFilterPhone';

export default {
  name: 'PageTasksMobile',
  components: {
    CallMobileFilterPhone,
    CallMobileTaskCard,
    CallMobileCheckedGroup,
    List,
    BFormCheckbox
  },
  data () {
    return {
      dataLoaded: false,
      dataLoadingList: true,
      dataLoadingListFinished: false,
      dataPageItemList: 0,
      dataShowFilter: false,
      dataDesc: true,
      dataOrderBy: 'execute_before',
      leavePage: true,
      newPlayer: true,
      windowTop: 0
    };
  },
  computed: {
    ...mapGetters({
      computedStoreSearch: 'mobile/search',
      computedStoreCurrentUser: 'auth/currentUser',
      computedSoreMobileTaskList: 'task/mobileTaskList',
      computedSoreMobilePaging: 'task/mobilePaging',
      computedStoreFilterPhone: 'mobile/filterPhone',
      computedStoreFilterTime: 'mobile/filterTime'
    }),
    showTaskSort () {
      return (
        this.computedStoreModelFilter !== 'closed' &&
          this.computedStoreModelFilter !== 'free'
      ) || (this.computedStoreModelFilter === 'free' && !this.newPlayer);
    },

    computedStoreModelFilter: {
      get () {
        return this.$store.state.task.mobileFilter;
      },
      set (data) {
        this.$store.commit(`task/${SET_ALL}`, {
          data,
          key: 'mobileFilter'
        });
      }
    },
    computedProjectId () {
      return this.$route.params?.projectId;
    },
    computedGroupName () {
      switch (this.computedStoreModelFilter) {
        case 'scheduled':
          return 'Scheduled';
        case 'own':
          return 'Others';
        case 'free':
          return 'Free';
        case 'closed':
          return 'Closed';
      }
      return '';
    }
  },
  watch: {
    computedStoreSearch: debounce(function () {
      if (
        this.computedStoreSearch === null ||
        this.$router?.currentRoute?.name !== 'ProjectTasks'
      ) {
        return;
      }
      this.onLoadList({ reset: true });
    }, 1000)
  },
  activated () {
    this.leavePage = true;
    if (this.$route.query.reset) {
      delete this.$route.query.reset;
      this.getProjectName();
      this.onLoadList({ reset: true });
    }
    sessionStorage.setItem('call-next-task', []);
  },

  async created () {
    this.leavePage = true;
    this.$watch('computedProjectId', () => {
      if (['ProjectTasks', 'ProjectTasksDetail'].includes(this.$route.name)) {
        return;
      }
      this.$store.commit(`task/${SET_ALL}`, {
        data: 'scheduled',
        key: 'mobileFilter'
      });
      this.$store.commit(`task/${SET_ALL}`, {
        data: [],
        key: 'mobileTaskList'
      });
      this.$store.commit(`task/${SET_ALL}`, {
        data: this.computedStoreFilterPhone.map((item) => {
          item.active = false;
          return item;
        }),
        key: 'filterPhone'
      });
    });

    this.onLoadList = ((options = {}) => {
      const { currentRoute: { name: pageName } = {} } = this.$router;

      const _filterKey = `_filter${pageName}`;

      if (!helper.isEmpty(this.$route.query, _filterKey)) {
        try {
          options = helper.clearObject(
            JSON.parse(this.$route.query[_filterKey])
          );

          const _arrFilter = ['type'];

          for (const _key of _arrFilter) {
            if (!helper.isEmpty(options, _key)) {
              switch (_key) {
                case 'type':
                  this.computedStoreModelFilter = options[_key];
                  break;
                case 'desc':
                  this.dataDesc = options[_key];
                  break;
                case 'orderBy':
                  this.dataOrderBy = options[_key];
                  break;
              }
            }
          }
        } catch (e) {
          console.log('Error query params');
        }
      }

      return async (
        {
          limit = 20,
          page = this.dataPageItemList,
          reset = false,
          desc = this.dataDesc,
          orderBy = this.dataOrderBy,
          is_new = this.newPlayer
        } = options || {}
      ) => {
        this.leavePage = true;
        if (reset) {
          this.dataPageItemList = 0;
          page = 0;
        }
        this.dataLoaded = true;
        const {
          payload: { list = [] }
        } = await this.$store.dispatch(`task/${TASKS_GET_ALL_MOBILE}`, {
          params: {
            search: this.computedStoreSearch,
            type: this.computedStoreModelFilter,
            project_ids: [this.computedProjectId],
            limit,
            page,
            ...(this.computedStoreModelFilter === 'free' && is_new && { is_new }),
            ...(this.computedStoreModelFilter === 'scheduled' ||
                this.computedStoreModelFilter === 'own'
              ? {
                  desc,
                  order_by: orderBy
                }
              : {
                  desc,
                  order_by: 'created_at'
                }),
            period: this.computedStoreFilterTime?.key,
            call_statuses: this.computedStoreFilterPhone.reduce(
              (itemA, itemB) => {
                if (itemB.active) {
                  itemA.push(itemB.key);
                }
                return itemA;
              },
              []
            )
          },
          reset
        });

        this.dataPageItemList += 1;

        if (reset) {
          this.dataLoadingListFinished = false;
        } else if (
          list.length === 0 ||
          this.computedSoreMobileTaskList.length >=
            helper.get(this.computedSoreMobilePaging, 'count', 0)
        ) {
          this.dataLoadingListFinished = true;
        }

        this.dataLoadingList = false;

        this.$router
          .replace({
            ...this.$router.currentRoute,
            query: {
              ...(this.$route.query || {}),
              [_filterKey]: JSON.stringify(
                helper.clearObject({
                  type: this.computedStoreModelFilter,
                  desc,
                  orderBy,
                  is_new
                })
              )
            }
          })
          .catch(() => {})
          .finally(() => { this.dataLoaded = false; });

        options = {};
      };
    })();

    Promise.all([this.getProjectName(), this.onLoadList()]);

    this.$watch('computedStoreModelFilter', (v) => {
      if (this.leavePage) this.onLoadList({ reset: true });
    });

    this.$root.$on('filter-active', (_) => {
      this.dataShowFilter = true;
    });
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll);
    this.startIntervalFreeTasks();
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    startIntervalFreeTasks () {
      const delay = 10000;
      setInterval(() => {
        if (this.leavePage &&
        this.windowTop >= 0 && this.windowTop <= 100 &&
        this.computedStoreModelFilter === 'free') {
          this.onLoadList({ reset: true });
        }
      }, delay);
    },

    onScroll (e) {
      this.windowTop = window.top.scrollY; /* or: e.target.documentElement.scrollTop */
    },

    async getProjectName () {
      const { project } = await this.$store.dispatch(`project/${PROJECTS_GET}`, {
        projectId: this.computedProjectId
      });

      this.$set(this.$route.meta, 'projectName', project.name);
      eventBus.$emit('reranderHeader');
    },

    onClickCard ({ id: taskId, player_id: playerId = 0 }) {
      this.$router.push({
        name: 'ProjectTasksDetail',
        params: {
          taskId,
          projectId: this.computedProjectId
        },
        query: {
          ...(this.$route.query || {}),
          // name: 'ID: ' + playerId,
          type: this.computedStoreModelFilter
        }
      });
    },
    clickSort () {
      this.dataDesc = !this.dataDesc;
      this.onLoadList({ reset: true });
    },
    onFilterPhone () {
      this.onLoadList({ reset: true });
    },
    onNewPlayer () {
      this.onLoadList({ reset: true });
    }
  },

  // eslint-disable-next-line vue/order-in-components
  beforeRouteLeave (from, to, next) {
    this.leavePage = false;
    next();
  }
};
</script>
