import { faArchive } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CallMobileCheckedGroup',
  props: {
    value: {
      type: String,
      default: 'scheduled'
    },
    dataLoaded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataModelChecked: this.value,
      dataIconClosedTask: faArchive
    };
  },
  watch: {
    dataModelChecked () {
      this.$emit('input', this.dataModelChecked);
    },
    value () {
      this.dataModelChecked = this.value;
    }
  },
  render (h) {
    return (
      <div
        class={`switcher-wrapper ${this.dataLoaded ? 'data-loaded' : ''}`}
      >
        <div class="switcher-item">
          <input
            id="radio-1"
            vModel={this.dataModelChecked}
            value="scheduled"
            type="radio"
            name="radio">  </input>
          <label for="radio-1">Scheduled</label>
        </div>
        <div class="switcher-item">
          <input
            id="radio-2"
            vModel={this.dataModelChecked}
            value="own"
            type="radio"
            name="radio"> </input>
          <label for="radio-2">Others</label>
        </div>
        <div class="switcher-item">
          <input
            id="radio-3"
            vModel={this.dataModelChecked}
            value="free"
            type="radio"
            name="radio"> </input>
          <label for="radio-3">Free</label>
        </div>
        <div class="switcher-item">
          <input
            id="radio-4"
            vModel={this.dataModelChecked}
            value="closed"
            type="radio"
            name="radio"> </input>
          <label for="radio-4">
            <font-awesome-icon icon={this.dataIconClosedTask}></font-awesome-icon>
          </label>
        </div>
      </div>
    );
  }
};
