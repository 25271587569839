<template>
  <popup
    v-model="dataShowPopup"
    position="bottom"
    :style="{ height: '460px' }"
    class="call-mobile-popup"
    closeable
    round
    @closed="() => this.$emit('input', false)">
    <div class="mobile-modal">
      <div class="mobile-modal__title">
        Filter
      </div>
      <div class="mobile-modal__body">
        <div>
          <div class="mobile-modal__body-title">
            Period
          </div>
          <v-select
            v-model="dataSelectLastDayValue"
            class="mobile-modal__time-filter"
            :options="dataSelectOptionsLastDay"
            :filterable="false"
            :searchable="false"
            :components="{Deselect: dataComponentDeselect}"
            placeholder="All Time"
            :class="[
              'call-select',
              dataSelectLastDayValue ? 'call-select--value' : null
            ]">
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <font-awesome-icon :icon="dataIconDown" />
              </span>
            </template>
          </v-select>
          <div class="mobile-modal__body-title">
            Status
          </div>
          <div
            v-for="list in filterPhone"
            :key="list.name"
            :class="{'is-active': list.active}"
            class="call-phone-checkbox-filter">
            <input
              :id="'subscribeNews' + list.name"
              v-model="list.active"
              type="checkbox">
            <label
              class="call-phone-checkbox-filter__title"
              :for="'subscribeNews' + list.name">
              {{ list.name }}
            </label>
          </div>
        </div>
        <div>
          <div class="mobile-modal__button-item">
            <call-button
              type="danger"
              plain
              full
              :icon="dataIconClose"
              @click="clickFilterCancel()">
              &nbsp;Clear Filter
            </call-button>
            <call-button
              type="success"
              full
              :icon="dataIconCheck"
              @click="clickFilterSend()">
              &nbsp;Filter
            </call-button>
          </div>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import { Popup } from 'vant';
import CallButton from '../../common/CallButton';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import selectSmsTemplate from '@/mixins/select/selectSmsTemplate';
import { mapGetters } from 'vuex';
import { SET_ALL } from '@/store/types/mutations.types';

export default {
  name: 'CallMobileFilterPhone',
  components: {
    Popup,
    CallButton
  },
  mixins: [selectSmsTemplate],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: [Number, String],
      default: null
    },
    playerId: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      dataIconCheck: faCheck,
      dataIconClose: faTimes,
      dataShowPopup: this.value,
      filterPhone: [
        {
          active: false,
          name: 'Successful',
          key: 'success'
        },
        {
          active: false,
          name: 'Cancelled',
          key: 'cancelled'
        },
        {
          active: false,
          name: 'No Answer',
          key: 'noanswer'
        },
        {
          active: false,
          name: 'Rejected',
          key: 'rejected'
        }
      ],
      dataSelectLastDayValue: null,
      dataSelectOptionsLastDay: [
        {
          key: 'day',
          label: 'Today'
        },
        {
          key: 'last_day',
          label: 'Yesterday'
        },
        {
          key: 'week',
          label: 'This Week'
        },
        {
          key: 'last_week',
          label: 'Last Week'
        },
        {
          key: 'month',
          label: 'This Month'
        },
        {
          key: 'last_month',
          label: 'Last Month'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      computedStoreFilterPhone: 'mobile/filterPhone'
    })
  },
  watch: {
    value () {
      this.dataShowPopup = this.value;
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickFilterSend () {
      this.$store.commit(`mobile/${SET_ALL}`, {
        data: this.filterPhone,
        key: 'filterPhone'
      });
      this.$store.commit(`mobile/${SET_ALL}`, {
        data: this.dataSelectLastDayValue,
        key: 'filterTime'
      });
      this.$emit('update', this.filterPhone);
      this.onCloseModal();
    },
    async clickFilterCancel () {
      this.dataSelectLastDayValue = null;
      this.filterPhone = this.filterPhone.map(item => {
        item.active = false;
        return item;
      });
      this.$store.commit(`mobile/${SET_ALL}`, {
        data: this.filterPhone,
        key: 'filterPhone'
      });
      this.$store.commit(`mobile/${SET_ALL}`, {
        data: this.dataSelectLastDayValue,
        key: 'filterTime'
      });
      this.$emit('update', this.filterPhone);
      this.onCloseModal();
    }
  }
};
</script>
