import { faClock } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CallBadgeTable',
  props: {
    type: {
      type: String,
      default: 'success'
    },
    icon: {
      type: Object,
      default: () => faClock
    },
    hideIcon: {
      type: Boolean,
      default: false
    }
  },
  render (h) {
    return (
      <p class={'project-card__player-badge info-badge info-badge--' + this.type}>
        {!this.hideIcon && <font-awesome-icon icon={this.icon}></font-awesome-icon>}
        {this.$slots.default}
      </p>
    );
  }
};
